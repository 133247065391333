

function DatePickerFunction(value) {
        new DateRangePicker(value);
}


function DatePickerFunctionOptions(value, options,cb) {
        new DateRangePicker(value, options,cb);
}


function DatePickerFunctionOptionsNew(value, options,cb) {
        new DateRangePicker(value, options,cb);
}

function WaterMark(value){
        new watermark(value)
}
